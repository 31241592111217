<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">小程序设置</span>
		</div>
		<div class="bg-white p-2 m-2">
			<el-form ref="form" :rules="rules" :model="form" label-width="160px" style="max-width: 750px;">
				<el-form-item label="小程序AppID" prop="app_id">
					<el-input v-model="form.app_id" placeholder="小程序AppID"></el-input>
				</el-form-item>
				<el-form-item label="小程序AppSecret" prop="app_secret">
					<el-input v-model="form.app_secret" placeholder="小程序AppSecret"></el-input>
				</el-form-item>
				<!-- <el-form-item label="支付商户号" prop="mchid">
					<el-input v-model="form.mchid" placeholder="支付商户号"></el-input>
				</el-form-item>
				<el-form-item label="支付密钥" prop="apikey">
					<el-input v-model="form.apikey" placeholder="微信支付密钥"></el-input>
				</el-form-item>
				<el-form-item label="apiclient_cert.pem" prop="cert_pem">
					<el-input type="textarea" v-model="form.cert_pem"></el-input>
				</el-form-item>
				<el-form-item label="apiclient_key.pem" prop="key_pem">
					<el-input type="textarea" v-model="form.key_pem"></el-input>
				</el-form-item> -->
				<el-form-item>
					<el-button type="primary" @click="submit('form')">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app', 'layout'],
		data() {
			return {
				form: {
					app_id: '',
					app_secret: '',
					mchid: '',
					apikey: '',
					cert_pem: '',
					key_pem: '',
				},
				rules: {
					app_id: [{
						required: true,
						message: '请输入小程序AppID',
						trigger: 'change'
					}, ],
					app_secret: [{
						required: true,
						message: '请输入小程序AppSecret',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getDetail()
		},
		methods: {
			getDetail() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/wxapp/setting'
					},
					method: 'get',
				}).then(res => {
					if (res.data.code == 1) {
						this.form.app_id = res.data.data.app_id;
						this.form.app_secret = res.data.data.app_secret;
						this.form.mchid = res.data.data.mchid;
						this.form.apikey = res.data.data.apikey;
						this.form.cert_pem = res.data.data.cert_pem;
						this.form.key_pem = res.data.data.key_pem;
					}
					this.layout.hideLoading()
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						this.axios({
							token: true,
							params: {
								s: 'store/wxapp/setting'
							},
							data: {
								wxapp: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'wxapp/setting'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
		},
	}
</script>

<style>
</style>
